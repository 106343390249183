var openDropdownID
var openDropdownButton

function dropDownMenu(dropdownID, buttonID) {
	if (openDropdownID) {
		var shouldNotOpen = openDropdownID == dropdownID
		dropDownMenuClose(openDropdownID, openDropdownButton)
		openDropdownID = null
		openDropdownButton = null
		if (shouldNotOpen) return
	}
	openDropdownID = dropdownID
	openDropdownButton = buttonID
	document.getElementById(dropdownID).classList.remove("hidden");
	document.getElementById(buttonID).classList.add("selected");
	document.getElementById(buttonID).parentNode.parentNode.classList.add("menuBtnBg");
	if (window.innerWidth < 760) {
		console.log('change icon')
		document.querySelector(`#${buttonID} + img`).style.display = "none"
		document.querySelector(`#${buttonID} + img + img`).style.display = "block"
	}

}
function dropDownMenuClose(dropdownID, buttonID) {
	openDropdownID = null
	openDropdownButton = null
	document.getElementById(dropdownID).classList.add("hidden");
	document.getElementById(buttonID).classList.remove("selected");
	document.getElementById(buttonID).parentNode.parentNode.classList.remove("menuBtnBg");
	if (window.innerWidth < 760) {
		document.querySelector(`#${buttonID} + img`).style.display = "block"
		document.querySelector(`#${buttonID} + img + img`).style.display = "none "
	}
}

var dropDownFundacionShouldLeaveTimeout
function dropDownFundacionShouldLeave(should, dropdownID, buttonID) {
	if (window.innerWidth < 760) return
	if (should) {
		dropDownFundacionShouldLeaveTimeout = setTimeout(() => {
			dropDownMenuClose(dropdownID, buttonID)
		}, 200);
	} else {
		clearTimeout(dropDownFundacionShouldLeaveTimeout)
	}
}

function menuOpen() {
	document.getElementById("menuId").style.display = "block";
	document.getElementById("menuBtnId").style.display = "none";
	document.querySelector('body').style.position = "fixed";
}
function menuClose() {
	document.getElementById("menuId").style.display = "none";
	document.getElementById("menuBtnId").style.display = "block";
	document.querySelector('body').style.position = "static";

}

//CAROUSEL

const slider = document.querySelector(".items");
const slides = document.querySelectorAll(".item");
const button = document.querySelectorAll(".button");
const arrowBack = document.querySelectorAll(".arrowBack");
const arrowForward = document.querySelectorAll(".arrowForward");
const progressbar = document.querySelector(".progress");

//PROGRESS BAR

const changeProgress = (progress) => {
	progressbar.style.width = `${progress}%`;
  };

//PHOTOS

let current = 1;
let prev = 0;
let next = 0;

for (let i = 0; i < button.length; i++) {
	button[i].addEventListener("click", () => i > 0 ? gotoNext() : gotoPrev());
}


const gotoPrev = () => current <= 4 >= 0 && gotoNum(current - 1);

const gotoNext = () => current >= 0 <= 3 && gotoNum(current + 1);

const gotoNum = number => {
	current = number;
	prev = current - 1;
	next = current + 1;

	for (let i = 0; i < slides.length; i++) {
		slides[i].classList.remove("active");
		slides[i].classList.remove("prev");
		slides[i].classList.remove("next");
	}

	if (current == 5) {
		current = 4
		prev = 3
		next = 5;
	}

	if (current == -1) {
		current = 0
		next = 1
	}

	slides[current].classList.add("active");
	slides[prev]?.classList.add("prev");
	slides[next]?.classList.add("next");

	console.log(current);
	console.log(next);
	console.log(prev);


if (current == 1) {
	changeProgress(25)
} else if (current == 2)
		changeProgress(50)
	else if (current == 3)
		changeProgress(75)
	else if (next == 5)
		changeProgress(100)
	else changeProgress(0)

}
